<template>
  <div data-testid="password-input">
    <p class="custom-label mb-1">{{ label }}</p>
    <v-text-field
      outlined
      dense
      :value="value"
      :rules="rules"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="showPassword ? 'text' : 'password'"
      :background-color="disabled ? 'ashRegular' : 'white'"
      @click:append="showPassword = !showPassword"
      @blur="emitUserPassword($event.target.value)"
    >
      <v-icon
        slot="append"
        :color="showPassword ? 'blueRegular' : 'greyRegular'"
        class="custom-icon"
        @click="showPassword = !showPassword"
        size="20"
      >
        {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon>
    </v-text-field>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: null,
    required: true,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Local variables ---
const showPassword = ref(false);

// --- Methods ---
const emitUserPassword = (password) => {
  emit('input', password);
  emit('change', password);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.custom-icon {
  margin: 2px 4px 0 0;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
