<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Form inputs</p>
      <v-container>
        <v-row justify="space-between" class="my-2">
          <!-- Text Input Section -->
          <v-col cols="6" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Text Inputs</p>
            <v-container>
              <!-- Default Text Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="defaultTextInput"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Disabled Text Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Disabled</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Disabled')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="disabledTextInput"
                    :disabled="true"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>
              <!-- Password Text Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Password</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Password')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <PasswordInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="password"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Icon text Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">With Icon</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('With Icon')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    icon="mdi-home-variant"
                    v-model="iconTextInput"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Prefix text Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">With Prefix</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('With Prefix')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    icon="mdi-currency-eur"
                    v-model="prefixTextInput"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <!-- Input Section -->
          <v-col cols="5" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Inputs</p>
            <v-container>
              <!-- Date Picker -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Date Picker</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Date Picker')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Date Picker -->
                  <DatePicker
                    label="Label"
                    placeholder="dd.mm.yyyy"
                    v-model="dateInput"
                  />
                  <!-- Date Picker -->
                </v-col>
              </v-row>

              <!-- Time picker -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Time Picker</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Time Picker')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Time Picker -->
                  <TimePicker
                    label="Label"
                    placeholder="hh:mm"
                    v-model="timeInput"
                  />
                  <!-- Time Picker -->
                </v-col>
              </v-row>

              <!-- Dropdown Select -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Dropdown</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Dropdown')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Dropdown -->
                  <DropdownInput
                    label="Label"
                    placeholder="Placeholder"
                    :items="dropdownArray"
                    v-model="dropdownInput"
                    @change="changeTriggered('dropdown')"
                  />
                  <!-- Dropdown -->
                </v-col>
              </v-row>

              <!-- Combobox Select -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Combobox</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Combobox')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Combobox -->
                  <ComboboxInput
                    label="Label"
                    placeholder="Placeholder"
                    :items="comboboxArray"
                    v-model="comboboxInput"
                    @change="changeTriggered('combobox')"
                  />
                  <!-- Combobox -->
                </v-col>
              </v-row>

              <!-- Search Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Search</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Search')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Search -->
                  <SearchInput
                    placeholder="Search..."
                    label="Label"
                    v-model="searchInput"
                    @update-list="updateList()"
                  />
                  <!-- Search -->
                </v-col>
              </v-row>

              <!-- File Input -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">File Input</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('File Input')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- File Input -->
                  <FileInput
                    label="Label"
                    v-model="fileInput"
                    placeholder="Choose a file..."
                    @change="changeTriggered('file input')"
                  />
                  <!-- File Input -->
                </v-col>
              </v-row>

              <!-- File Input Downloadable -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">File Input Downloadable</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('File Input Downloadable')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- File Input -->
                  <FileInput
                    label="Label"
                    :downloadable="true"
                    v-model="fileInputDownloadable"
                    placeholder="Choose a file..."
                    @change="changeTriggered('file input downloadable')"
                    @display-document="displayDocument()"
                    @download-document="downloadDocument()"
                  />
                  <!-- File Input -->
                </v-col>
              </v-row>

              <!-- File Input Deletable -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">File Input Deletable</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('File Input Deletable')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- File Input -->
                  <FileInput
                    label="Label"
                    deletable
                    v-model="fileInputDeletable"
                    placeholder="Choose a file..."
                    @change="changeTriggered('file input deletable')"
                    @display-document="displayDocument()"
                    @delete-document="deleteDocument()"
                  />
                  <!-- File Input -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row justify="space-between" class="my-2 mt-6">
          <!-- Text Area Section -->
          <v-col class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Text Area</p>
            <v-container>
              <!-- Default Text Area -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default Text Area')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Area -->
                  <TextArea
                    label="Label"
                    placeholder="Placeholder"
                    v-model="textArea"
                  />
                  <!-- Text Area -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import TimePicker from '@/components/shared/customComponents/inputs/time-picker.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import PasswordInput from '@/components/shared/customComponents/inputs/password-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import ComboboxInput from '@/components/shared/customComponents/inputs/combobox-input.vue';
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import TextArea from '@/components/shared/customComponents/inputs/text-area.vue';

export default {
  name: 'FormInputWiki',
  components: {
    DatePicker,
    TimePicker,
    PasswordInput,
    RegularTextInput,
    DropdownInput,
    ComboboxInput,
    SearchInput,
    FileInput,
    TextArea,
  },

  data: () => ({
    defaultTextInput: '',
    disabledTextInput: '',
    password: 'secretPassword',
    iconTextInput: '',
    prefixTextInput: '',
    dateInput: '',
    dropdownInput: null,
    dropdownArray: [
      { text: 'First Item', value: 1 },
      { text: 'Second Item', value: 2 },
      { text: 'Third Item', value: 3 },
      { text: 'Fourth Item', value: 4 },
      { text: 'Fifth Item', value: 5 },
      { text: 'Sixth Item', value: 6 },
      { text: 'Seventh Item', value: 7 },
      { text: 'Eighth Item', value: 8 },
      { text: 'Ninth Item', value: 9 },
      { text: 'Tenth Item', value: 10 },
    ],
    comboboxInput: null,
    comboboxArray: [
      { text: 'First Item', value: 1 },
      { text: 'Second Item', value: 2 },
      { text: 'Third Item', value: 3 },
      { text: 'Fourth Item', value: 4 },
      { text: 'Fifth Item', value: 5 },
      { text: 'Sixth Item', value: 6 },
      { text: 'Seventh Item', value: 7 },
      { text: 'Eighth Item', value: 8 },
      { text: 'Ninth Item', value: 9 },
      { text: 'Tenth Item', value: 10 },
    ],
    searchInput: '',
    fileInput: null,
    fileInputDownloadable: null,
    fileInputDeletable: null,
    textArea: '',
    timeInput: '',
  }),

  methods: {
    updateList() {
      console.log('List updated');
    },

    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Default':
          selectedComponent = `<RegularTextInput
              label="Label"
              placeholder="Placeholder"
              v-model="defaultTextInput"
            />`;
          break;
        case 'Disabled':
          selectedComponent = `<RegularTextInput
              label="Label"
              placeholder="Placeholder"
              v-model="disabledTextInput"
              :disabled="true"
            />`;
          break;
        case 'Password':
          selectedComponent = `<PasswordInput
              label="Label"
              placeholder="Placeholder"
              v-model="password"
            />`;
          break;
        case 'With Icon':
          selectedComponent = `<RegularTextInput
              label="Label"
              placeholder="Placeholder"
              icon="mdi-home-variant"
              v-model="iconTextInput"
            />`;
          break;
        case 'With Prefix':
          selectedComponent = `<RegularTextInput
              label="Label"
              placeholder="Placeholder"
              icon="mdi-currency-eur"
              v-model="prefixTextInput"
            />`;
          break;
        case 'Date Picker':
          selectedComponent = `<DatePicker
              label="Label"
              placeholder="dd.mm.yyyy"
              v-model="dateInput"
            />`;
          break;
        case 'Dropdown':
          selectedComponent = `<DropdownInput
              label="Label"
              placeholder="Placeholder"
              :items="dropdownArray"
              v-model="dropdownInput"
              @change="changeTriggered"
            />`;
          break;
        case 'Combobox':
          selectedComponent = `<ComboboxInput
              label="Label"
              placeholder="Placeholder"
              :items="comboboxArray"
              v-model="comboboxInput"
              @change="changeTriggered"
            />`;
          break;
        case 'Search':
          selectedComponent = `<SearchInput
              label="Label"
              placeholder="Search..."
              v-model="searchInput"
              @update-list="updateList()"
            />`;
          break;
        case 'File Input':
          selectedComponent = `<FileInput
              label="Label"
              v-model="fileInput"
              placeholder="Choose a file..."
              @change="changeTriggered('file input')"
            />`;
          break;
        case 'File Input Downloadable':
          selectedComponent = `<FileInput
              label="Label"
              downloadable
              v-model="fileInputDownloadable"
              placeholder="Choose a file..."
              @change="changeTriggered('file input downloadable')"
              @display-document="displayDocument()"
              @download-document="downloadDocument()"
            />`;
          break;
        case 'File Input Deletable':
          selectedComponent = `<FileInput
              label="Label"
              deletable
              v-model="fileInputDeletable"
              placeholder="Choose a file..."
              @change="changeTriggered('file input deletable')"
              @display-document="displayDocument()"
              @delete-document="deleteDocument()"
            />`;
          break;
        case 'Default Text Area':
          selectedComponent = `<TextArea
            label="Label"
            placeholder="Placeholder"
            v-model="textArea"
            />`;
          break;
        case 'Time Picker':
          selectedComponent = `<TimePicker
              label="Label"
              placeholder="Placeholder"
              v-model="time"
            />`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },

    changeTriggered(name) {
      console.log(`The value of ${name} was changed`);
    },

    displayDocument() {
      console.log('Display document triggered');
    },

    downloadDocument() {
      console.log('Download document triggered');
    },

    deleteDocument() {
      console.log('Delete document triggered');
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
